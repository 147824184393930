.RAF::-webkit-scrollbar {
    width: 8px;
}

.RAF::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #bee3f8;
    border-radius: 10px;
}

.RAF::-webkit-scrollbar-thumb {
    background: #6417ff;
    border-radius: 10px;
}


.RAF {
    /*display: flex; */
    background: #fff;
    /* padding-right: 15px; */
    border-radius: 10px 10px 10px 10px;
    position: absolute;
    width: 400px;
    height: auto;
    max-height: 85vh;
    overflow-y: auto;
    right: 1.5%;
    bottom: 12.5%;
    border: 1px solid #bee3f8;
    /* box-shadow: 2px 5px 10px #dcdcdc; */
    z-index: 9999;
}
.ReferClose {
    position: absolute;
    padding: 5px;
}
.Custom-d-flex{
    display: flex;
    align-items: center;
    padding-top: 5px;
}
#referfriend {
    /* width: 50%; */
    /* padding-right: 10px; */
}

.socialMainWrapper {
    /* width: 50%; */
    /* padding-left: 10px; */
}

.socialhead {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    /* padding: 12px 10px; */
    /* color: #6417ff; */
    padding-top: 10px;
}

input.form-control {
    padding: 6px 12px;
    border: 1px solid #bee3f8;
    border-radius: 5px;
    width: 100%;
}

.ReferalLink {
    /* padding-top: 5px; */
    /* margin-bottom: 20px; */
    text-align: center;
    align-items: center;
    /* display: flex; */
    width: 65%;
}

input.form-control {
    padding: 5px 12px;
    border: 1px solid #bee3f8;
    border-radius: 5px;
    width: 65%;
}

.URLCopy {
    padding: 8px 10px;
    background: #6417ff;
    /* border: 1px solid #bee3f8; */
    color: white;
    margin-left: -3px;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}

.URLCopy:hover {
    opacity: 0.8;
}

.ReferalLink button {
    text-align: right;
    justify-content: end;
    margin-left: 5px;
    padding: 5px 10px;
    font-size: 14px;
    align-items: center;
    margin-top: -5px;
    line-height: unset;
}

.SocialLinks {
    /*  margin-top: 10px!important; */
    text-align: center;
    /* display: block; */
    /* border: 1px solid #bee3f8; */
    /* padding: 15px; */
    /* background: aliceblue; */
    border-radius: 5px;
    /* width: 45%; */
    /* margin: 0 auto;*/
}

h3.SocialTitle {
    /* font-size: 22px; */
    text-align: center;
    font-weight: 800;
    /* padding-top: 15px; */
}

button.fbsocial {
    padding-left: 0px;
    /* font-size: 18px; */
}

button.fbsocial:hover {
    opacity: 0.8;
}

button.twsocial:hover {
    opacity: 0.8;
}

button.fbsocial:focus-visible {
    outline: unset !important;
}

button.twsocial:focus-visible {
    outline: unset !important;
}

.SocialIcons svg {
    color: #6417ff;
}

@media screen and (max-width: 767px) {
    .RAF {
        width: 100%;
        height: auto;
        max-height: 85vh;
        /* top: 0; */
        right: 0;
        bottom: 11%;
        z-index: 9999;
    }
}