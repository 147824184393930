body {
  background-color: #ffffff !important;
}

.ag-theme-alpine .ag-cell-focus {
  --ag-range-selection-border-style: none;
}

.ag-theme-alpine,
.ag-theme-alpine-dark {
  --ag-alpine-active-color: #2C3E5D !important;
  --ag-input-focus-border-color: 0 !important;
}


.pl-5 {
  padding-left: 5px !important;
  display: flex;
  align-items: center;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-0 {
  margin-top: 0 !important;
}

/* Below animations are fore modal created using React-Modal */
/* a {
  color: #2C3E5D;
} */

.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

/* .gztrev {
  z-index: 110 !important;
} */

label+.MuiInput-formControl {
  margin-left: 10px;
}

.MuiFormControl-root {
  display: block !important;
}

.MuiInputLabel-root {
  display: contents !important;
}

#mobileLinks {
  z-index: 110 !important;
}

#mobileLinksCross {
  z-index: 150 !important;
}

#positionFixed {
  position: fixed;
  right: 6%;
  top: 5%;
}

#SocialLoginBtn {
  background-color: #F8F8F8 !important;
  border: unset;
}

#SocialLoginBtn:hover {
  background-color: #F8F8F8 !important
}

#card {
  font-size: 4rem;
}

#yesButtons {
  background: #2C3E5D;
  margin-right: 10px !important;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
}

#bulletLeftMargin {
  margin-left: 25px !important;
}

textarea#standard-multiline-static {
  width: 100% !important;
  border: 1px solid #767676 !important;
  overflow: auto !important;
  height: 200px !important;
  display: block;
  padding-left: 5px;
  padding-top: 5px;
}

.radioControl {
  display: block !important;
}

#demo-simple-select-label {
  color: black;
  font-weight: 600;
}

.MuiFormLabel-root {
  line-height: 2 !important;
}

.feedbackButoon {
  width: max-content !important;
  min-width: 200px !important;
  float: right !important;
}

.connectorBtn {
  max-width: 122 px;
}

.accountDelBtn {
  background-color: red;
}

.MuiTypography-h4 {
  color: #000;
  text-transform: capitalize;
}

/* ----------- Figma Css Changes ------------*/
main {
  background: #F0F6FF;
  border-radius: 16px;
  margin-top: 15px;
  min-height: 80vh;
}

.group.group1 {
  border-radius: 8PX !important;
}

.group {
  /* border-radius: 45px 5px 5px !important; */
  background-color: #fff !important;
  /* border: unset; */
  box-shadow: unset;
}

.ActiveCard {
  border-bottom: 6px solid #5071A8;
}

div#CardContent {
  display: flex;
  align-items: center;
  text-align: left;
}

#cardValue {
  color: #000;
  font-size: 56px;
  padding-right: 15px;
  font-weight: 600;
  font-family: 'Inter';
}

#cardHeader {
  color: #2C3E5D;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Inter';
}

#cardDesc {
  color: #7D7F83;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter';
}

#GridHeading {
  font-size: 23px;
  color: #2C3E5D;
  font-family: 'Inter';
  font-weight: 600;
}

#GridSubHeading {
  color: #2C3E5D;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Inter';
}

button#Markneded {
  background: #2C3E5D;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

button#Refresh {
  background: #5071A8;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.OnHover {
  visibility: hidden;
  width: 120px;
  background-color: #5071A8;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  left: 110%;
  margin-left: 0px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter';
  text-transform: capitalize;
}

.OnHover::after {
  content: "";
  position: absolute;
  top: 35%;
  left: 0%;
  transform: rotate(90deg);
  margin-left: -10px;
  border-width: 5px;
  border-style: solid;
  border-color: #5071a8 transparent transparent transparent;
}

button#Markneded:hover .OnHover {
  visibility: visible;
  opacity: 1;
}

button#RequestDeletion {
  background: #2C3E5D;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.OnHover1 {
  visibility: hidden;
  width: 120px;
  background-color: #5071A8;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  right: 105%;
  margin-left: 0px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter';
  text-transform: capitalize;
}

.OnHover1::after {
  content: "";
  position: absolute;
  top: 35%;
  right: -8%;
  transform: rotate(270deg);
  margin-left: 9px;
  border-width: 5px;
  border-style: solid;
  border-color: #5071a8 transparent transparent transparent;
}

button#RequestDeletion:hover .OnHover1 {
  visibility: visible;
  opacity: 1;
}

/* Grid Css */
#DSRCustomTable .MuiDataGrid-columnHeaders {
  border-bottom: unset !important;
}

#DSRCustomTable .MuiDataGrid-root {
  border: unset !important;
}

#DSRCustomTable .MuiDataGrid-columnHeader {
  min-width: 200px !important;
  /* max-width: 150px !important; */
}

#DSRCustomTable .MuiDataGrid-columnHeader:nth-child(1) {
  min-width: 50px !important;
}

#DSRCustomTable .MuiDataGrid-columnHeader:nth-child(2) {
  min-width: 240px !important;
}

#DSRCustomTable .MuiDataGrid-columnHeader:nth-child(3) {
  min-width: 180px !important;
}

#DSRCustomTable .MuiDataGrid-cell {
  border-bottom: unset !important;
  color: #1E2B40;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter';
  min-width: 200px !important;
  /* max-width: 150px !important; */
  word-break: break-word;
  white-space: normal;
}

#DSRCustomTable .MuiDataGrid-cell:nth-child(1) {
  min-width: 50px !important;
}

#DSRCustomTable .MuiDataGrid-cell:nth-child(2) {
  min-width: 240px !important;
  cursor: pointer;
  word-break: unset;
  white-space: normal;
}

#DSRCustomTable .MuiDataGrid-cell:nth-child(3) {
  min-width: 180px !important;
}

#DSRCustomTable .MuiDataGrid-cell:nth-child(8) {
  display: none;
}

#DSRCustomTable .MuiDataGrid-row {
  background: #fff;
  margin-bottom: 10px !important;
  border-radius: 4px;
}

#DSRCustomTable .MuiDataGrid-row.Mui-selected {
  background-color: rgba(255, 255, 255, 0.6);
}

#DSRCustomTable .MuiDataGrid-footerContainer {
  display: none;
}

#DSRCustomTable .MuiDataGrid-columnHeaderTitle {
  color: #888888;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Inter';
}

#DSRCustomTable .Mui-checked,
.MuiCheckbox-indeterminate {
  color: #2C3E5D;
}

#DSRCustomTable .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
  outline: unset !important;
}

#DSRCustomTable .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

#DSRCustomTable svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  width: 20px;
}

#DSRCustomTable .imgid {
  margin-right: 5px;
  margin-left: 1px;
}

/* Grid css Next */

#DSRCustomTable1 .MuiDataGrid-columnHeaders {
  border-bottom: unset !important;
}

#DSRCustomTable1 .MuiDataGrid-root {
  border: unset !important;
}

#DSRCustomTable1 .MuiDataGrid-columnHeader {
  min-width: 155px !important;
  /* max-width: 150px !important; */
}

#DSRCustomTable1 .MuiDataGrid-columnHeader:nth-child(1) {
  min-width: 215px !important;
}

#DSRCustomTable1 .MuiDataGrid-columnHeader:nth-child(2) {
  min-width: 180px !important;
}

#DSRCustomTable1 .MuiDataGrid-columnHeader:nth-child(3) {
  min-width: 180px !important;
}

#DSRCustomTable1 .MuiDataGrid-cell {
  border-bottom: unset !important;
  color: #1E2B40;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter';
  min-width: 155px !important;
  /* max-width: 150px !important; */
  word-break: break-word;
  white-space: normal;
}

#DSRCustomTable1 .MuiDataGrid-cell:nth-child(1) {
  min-width: 215px !important;
  cursor: pointer;
  word-break: unset;
  white-space: normal;
}

#DSRCustomTable1 .MuiDataGrid-cell:nth-child(2) {
  min-width: 180px !important;
}

#DSRCustomTable1 .MuiDataGrid-cell:nth-child(3) {
  min-width: 180px !important;
}

#DSRCustomTable1 .MuiDataGrid-cell:nth-child(8) {
  display: none;
}

/* #DSRCustomTable1 .MuiDataGrid-cell:nth-child(7) {
  min-width: 300px !important;
} */

#DSRCustomTable1 .MuiDataGrid-row {
  background: #fff;
  margin-bottom: 10px !important;
  border-radius: 4px;
}

#DSRCustomTable1 .MuiDataGrid-row.Mui-selected {
  background-color: rgba(255, 255, 255, 0.6);
}

#DSRCustomTable1 .MuiDataGrid-footerContainer {
  display: none;
}

#DSRCustomTable1 .MuiDataGrid-columnHeaderTitle {
  color: #888888;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Inter';
}

/* #DSRCustomTable1 .Mui-checked, .MuiCheckbox-indeterminate {
  color: #eaf1fd;
} */

#DSRCustomTable1 .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
  outline: unset !important;
}

#DSRCustomTable1 .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

#DSRCustomTable1 svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  width: 20px;
}

#DSRCustomTable1 .imgid {
  margin-right: 5px;
  margin-left: 0;
}



#DSRCustomTable2 .MuiDataGrid-columnHeaders {
  border-bottom: unset !important;
}

#DSRCustomTable2 .MuiDataGrid-root {
  border: unset !important;
}

#DSRCustomTable2 .MuiDataGrid-columnHeader {
  min-width: 185px !important;
  /* max-width: 150px !important; */
}

#DSRCustomTable2 .MuiDataGrid-columnHeader:nth-child(1) {
  min-width: 265px !important;
}

#DSRCustomTable2 .MuiDataGrid-columnHeader:nth-child(2) {
  min-width: 200px !important;
}

#DSRCustomTable2 .MuiDataGrid-columnHeader:nth-child(3) {
  min-width: 200px !important;
}

#DSRCustomTable2 .MuiDataGrid-cell {
  border-bottom: unset !important;
  color: #1E2B40;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter';
  min-width: 185px !important;
  /* max-width: 150px !important; */
  word-break: break-word;
  white-space: normal;
}

#DSRCustomTable2 .MuiDataGrid-cell:nth-child(1) {
  min-width: 265px !important;
  cursor: pointer;
  word-break: unset;
  white-space: normal;
}

#DSRCustomTable2 .MuiDataGrid-cell:nth-child(2) {
  min-width: 200px !important;
}

#DSRCustomTable2 .MuiDataGrid-cell:nth-child(3) {
  min-width: 200px !important;
}

#DSRCustomTable2 .MuiDataGrid-cell:nth-child(7) {
  display: none;
}


/* #DSRCustomTable2 .MuiDataGrid-cell:nth-child(7) {
  min-width: 300px !important;
} */

#DSRCustomTable2 .MuiDataGrid-row {
  background: #fff;
  margin-bottom: 10px !important;
  border-radius: 4px;
}

#DSRCustomTable2 .MuiDataGrid-row.Mui-selected {
  background-color: rgba(255, 255, 255, 0.6);
}

#DSRCustomTable2 .MuiDataGrid-footerContainer {
  display: none;
}

#DSRCustomTable2 .MuiDataGrid-columnHeaderTitle {
  color: #888888;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Inter';
}

/* #DSRCustomTable2 .Mui-checked, .MuiCheckbox-indeterminate {
  color: #eaf1fd;
} */

#DSRCustomTable2 .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
  outline: unset !important;
}

#DSRCustomTable2 .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

#DSRCustomTable2 svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  width: 20px;
}

#DSRCustomTable2 .imgid {
  margin-right: 5px;
  margin-left: 0;
}

/* Grid Css End */

#DomainSearch input.MuiInputBase-input.MuiInput-input::placeholder {
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter';
}

#DomainSearch button {
  color: #CCD2E3;
  /* margin-right: 0; */
}

/* #DomainSearch button:nth-child(3) {
  display: none;
} */

.DomainDetailsHeader {
  padding-top: 0px !important;
  margin-top: -12px;
  padding-left: 0px !important;
}

.DomainDetailsHeader .MuiTypography-h5 {
  font-size: 24px;
  color: #2C3E5D;
  font-weight: 600;
  font-family: 'Inter';
}

.subTitle {
  color: #888888;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Inter';
}

.subBody {
  color: #2C3E5D;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Inter';
}

.View_d-flex {
  display: flex;
  align-items: flex-start;
}

.ViewRequestDeletionData {
  width: 100%;
  /* margin-top: 15px; */
}

.ViewRequestDeletionLogo {
  display: flex;
  text-align: left;
  margin-bottom: 15px;
  margin-right: 20px;
}

.ViewRequestDeletionLogo a.DomainDetailName {
  color: #2C3E5D;
  font-size: 23px;
  font-weight: 600;
}

.DomainStatus {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter';
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.DomainStatus img {
  margin-right: 10px;
}

.ViewTableCell {
  border-bottom: unset !important;
  padding: 10px 0px !important;
  word-break: break-all;
}

.RequestDeletionBtn {
  background-color: #2C3E5D !important;
  color: #fff !important;
  font-size: 12px;
  font-weight: 500 !important;
  font-family: 'Inter';
  margin-right: 20px;
}

.CancelBtn {
  background-color: #fff !important;
  border: 1px solid #9396A8;
  color: #888888 !important;
  font-size: 12px;
  font-weight: 500 !important;
  font-family: 'Inter';
}

.mr-10 {
  margin-right: 10px;
}

.TableFIlter svg {
  width: 20px;
}

.TableFIlter path {
  fill: #2C3E5D;
}

.FilterLabel {
  color: #2C3E5D;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter';
}

#DomainLogoAlpha {
  font-size: 4rem;
  background: #E3EDFE;
  width: 100px;
  text-align: center;
  height: 100px;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  border-radius: 8px;
  position: relative;
  font-weight: 600;
  color: #000000;
}

.CustomFilter {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

div#SearchWrapper {
  margin-right: 15px;
  width: 40%;
}

.DomainTableOuterInput {
  background: #fff;
  border-radius: 4px;
  min-height: 42px;
  padding: 3px;
  align-items: center;
  display: flex;
  justify-content: end;
  width: 60%;
}

.MuiBackdrop-root {
  background-color: rgba(80, 113, 168, 0.8) !important;
  overflow: auto;
}

.GridDomainStatus {
  color: #222222;
  font-size: 10px;
  font-weight: 300;
  font-family: 'Inter';
  /* padding-left: 5px; */
}

.BrandLogo {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000 !important;
  text-transform: uppercase;
}

.NewNavLink {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'inter';
  color: #888888;
}

a.NewNavLink:hover {
  color: #2C3E5D;
}

.NewNavLink.DarkNavLink {
  color: #2C3E5D;
  margin-right: 0;
}

.NewNavLink.DarkNavLink:hover {
  color: #2C3E5D;
  margin-right: 0;
}

.NewNavLink.DarkNavLink:focus {
  color: #2C3E5D;
  margin-right: 0;
}

.PFMNavOption {
  background-color: #ffffff;
  border: unset;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.25);
}

.PFMNavOption img {
  width: 1rem !important;
}

img.ArrowIcon {
  width: 0.8rem !important;
}

img.ArrowIconDown {
  width: 0.8rem !important;
  transform: rotate(180deg);
}

#simple-popper button {
  width: 100%;
  color: #2C3E5D;
  font-size: 14px;
  font-weight: 500;
}

#simple-popper button:hover {
  background: #E3EDFE;
}

img.PFMUser {
  width: 2.5rem !important;
}

.LoginUserAccount {
  position: absolute;
  margin-top: 9px;
  margin-left: -10px;
}

.TimeFrameOption select {
  font-size: 14px;
  color: #3457B4;
  font-weight: 400;
}

.TimeFrameOption:before {
  border: unset !important;
}

.TimeFrameOption .MuiSelect-icon {
  color: #5071A8;
}

.pr-10 {
  padding-right: 10px !important;
}

.SwitchComplete {
  padding-left: 5px;
  color: #5071A8;
  font-size: 12px;
  font-weight: 600;
}

.failedStatus {
  color: #E63E3E;
  font-size: 12px;
  font-weight: 600;
}

.completedStatus {
  color: #53C17F;
  font-size: 12px;
  font-weight: 600;
}

.Progress_d-flex {
  display: flex;
  align-items: center;
}

.AccountHeading {
  font-size: 32px !important;
  font-weight: 600 !important;
  color: #2C3E5D !important;
  font-family: "inter" !important;
}

button#AccountTab {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter';
  color: #9396A8;
  padding-left: 0px;
  padding-bottom: 2px;
  margin-right: 20px;
}

#AllDomainTab .Mui-selected {
  color: #2C3E5D !important;
  border-bottom: 4px solid #2C3E5D;
}

.DeletingAcBody {
  color: #2C3E5D;
  font-size: 16px;
  font-family: 'Inter';
}

.TabPanelHeading {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #2C3E5D !important;
  font-family: 'Inter' !important;
}

.PageActive {
  color: #2C3E5D;
  background: #F0F6FF;
  padding: 10px;
  border-radius: 4px;
  padding-bottom: 10px !important;
}

.accountDelBtn {
  color: #1E2B40;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.5;
}

.EyeIcon {
  position: relative;
  margin-top: -28px;
  float: right;
  margin-right: 5px;
}

div#EmailConBox {
  background: #ffffff;
  margin: 5px 10px;
  border-radius: 8px;
  padding: 10px 15px;
  height: 75px;
}

#ChangePassRule {
  /* margin-left: 20px; */
}

#ChangePassRule p {
  font-weight: 400;
  font-size: 12px;
  color: #2C3E5D;
  padding: 4px;
  font-family: 'Inter';
}

#ChangePassRule .CustomFlex {
  display: flex;
  align-items: center;
}

#EmailConBox .MuiButton-outlinedPrimary {
  color: #2C3E5D;
  border: 1px solid #2C3E5D;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  padding: 6px 14px;
  text-transform: capitalize;
  min-width: 100px !important;
}

#EmailConBox .MuiButton-outlinedSecondary {
  color: #E73E3E;
  border: 1px solid #E73E3E;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  padding: 6px 14px;
  text-transform: capitalize;
  min-width: 100px !important;
}

#Text_Right {
  text-align: right;
}

a:-webkit-any-link:focus-visible {
  outline-offset: unset;
  outline: unset;
  color: unset;
}

.Disabled {
  color: #C4C8D0;
}

.DesktopView {
  display: none;
}

#EmailConBox .MuiButton-outlinedSecondary {
  margin-right: 15px;
}

.CopyURLBtn {
  float: right;
}

.TimeFrameOption select option {
  font-size: 14px;
}

.ConnectLogo {
  display: flex;
}

.LoginPopup {
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 16px !important;
}

.SocialLoginButtons {
  background-color: #F8F8F8;
}

.EasyStepsContainer {
  display: flex;
  margin-left: 5%;
}

.EasyStepHeadingMain {
  display: flex;
}

.EasyStepsBtn {
  transform: rotate(90deg);
  padding-top: 30px;
}

.EasyStepsContent {
  padding-right: 50px;
}

.YourDataContainer {
  margin-bottom: 15px;
}

.YourDataContent {
  background: rgba(243, 245, 255, 0.7);
  border-radius: 8px;
  margin-right: 15px;
}

.imageContainer {
  background: #ffffff;
  border-radius: 8px;
}

.VideoSection {
  background: url(./images/VideoSection.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 420px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: -20px;
  margin-right: -20px;
}

.VideoFrame {
  padding: 15px;
}

.ThePrincipale {
  margin-left: -20px;
  margin-right: -20px;
}

.ThePrincipaleText {
  text-align: center;
  display: inline-block;
  width: 70%;
}

.PrincipalImg img {
  width: 50%;
}

.PWCFooter {
  margin-left: -20px;
  margin-right: -20px;
  background: #1D2739;
}

.HeaderBg {
  background: url(./images/HeaderBg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  margin: -20px;
  padding: 20px;

}

.Headerimg {
  justify-content: center !important;
}

.Headerimg img {
  width: 60%;
}

img.PrivacyImg {
  width: 70%;
  justify-content: center;
  margin: 0 auto;
}

.AppDotBg {
  background: url(./images/AppBG.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

#LoginNavLink {
  background: #ffffff;
  margin-right: 20px;
  color: #1D2739;
  cursor: pointer;
}

#accountPopup {
  text-transform: uppercase;
  background-color: #E25668;
  border-radius: 8px;
  cursor: pointer;
}

#GetStartedBtn {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
}

#GetStartedstepsBtn {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  transform: rotate(180deg);
}

#PersonalDescription {
  color: #757575;
}

/* div#CardContent::after {
  content: "";
  position: absolute;
  top: 3.2%;
  left: 0%;
  width: 0px;
  height: 0px;
  border-top: 75px solid #6415ff;
  border-right: 75px solid transparent;
} */

#card {
  /* font-size: 4rem;
  border-radius: 50%;
  width: 42%;
  text-align: center;
  margin: 0 auto;
  background: #bee3f8;
  margin-bottom: 15px;
  padding: 8px; */
  /* font-size: 2.5rem;
  text-align: center;
  background: #bee3f8;
  margin-bottom: 15px !important;
  padding: 8px;
  min-width: 90px;
  max-width: max-content;
  min-height: 85px;
  max-height: max-content;
  margin: 0 auto;
  border-radius: 50% 0% 55% 55%;
  position: relative; */
}

div#FooterButton button {
  width: 90%;
}

div#DataFooterButton button {
  margin-bottom: 0px;
  border-radius: 0px 5px 5px 0;
  padding: 15px;
  width: 50%;
  /* margin-right: 1px; */
  margin-top: 0px !important;
}

div#DataFooterButton button:nth-child(1) {
  margin-right: 1px;
}

button.ViewDetails {
  margin: 0px !important;
}

div#DataFooterButton button:nth-child(1) {
  border-radius: 5px 0px 0px 5px;
}

div#DSRButton {
  display: block;
}

div#DataSubjectButton button {
  width: 100%;
  margin: 0px;
}

div#DataSubjectButton button:nth-child(1) {
  border-radius: 5px 5px 0px 0px;
}

div#DataSubjectButton button:nth-child(2) {
  background-color: #2C3E5D;
  color: #fff;
  border: unset;
  border-radius: 0px 0px 5px 5px;
}

div#DataSubjectButtonFail button:nth-child(2) {
  background-color: red;
  color: #fff;
  border: unset;
  border-radius: 0px 0px 5px 5px;
  cursor: not-allowed;
  pointer-events: none;
}

div#DataSubjectButtonFail button {
  width: 100%;
  margin: 0px;

}

div#DSRButton button {
  margin-bottom: 0px;
  width: 100%;
}

div#DataLogo {
  width: 100%;
  height: 8rem;
}

/* div#DataLogo img {
  width: 100%;
} */
div#DataLogo img {
  width: auto;
  padding: 15px;
  border-radius: 20px;
  height: 100%;
}

span#WelcomeName {
  font-weight: 600;
  color: blue;
  font-size: 22px;
}

#DataDomain {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #6415ff;
  text-transform: uppercase;
  text-decoration: underline;
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

p#DataEmail {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

p#DataEmail:hover {
  text-overflow: unset;
  white-space: break-spaces;
}

div#CardDetails {
  padding-top: 10px;
  font-size: 12px;
}

div#CardDetails td:nth-child(3) {
  padding-left: 5px;
}

.sc-ikXwFM.jBibxD {
  width: 25%;
}

.disablereqbtn {
  cursor: not-allowed !important;
  /* background-color: rgb(229, 229, 229) !important; */
  opacity: 0.5 !important;
  pointer-events: unset !important;

}

iframe {
  text-align: center;
  margin: 0 auto;
}

div#getstartwrap {
  margin-bottom: 0px !important;
}

div#getstartinnerwrap {
  padding-bottom: 0px !important;
}

div#EmailPreview {
  padding: 1rem 2rem !important;
}

div#EmailPreview h3 {
  color: #2C3E5D;
  text-transform: capitalize;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 15px;
}

div#EmailPreview h6 {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #7D7F83;
  padding-bottom: 15px;
}

div#EmailPreview p {
  color: #1E2B40;
  font-size: 14px;
  font-weight: 400;
  word-break: break-word;
}

button#sendMail_Btn {
  background: #2C3E5D;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  margin-left: 0px;
  margin-right: 15px;
}

button#sendMail_Btn\ SendBtn_Transparent {
  background: transparent;
  border: 2px solid #9396A8;
  font-size: 12px;
  font-weight: 500;
  color: #888888;
  margin-left: 0px;
}

div#ModalContent {
  text-align: center;
}

.ChangePassInput {
  background: #ffffff;
  color: #2C3E5D;
  font-size: 14px;
  font-weight: 400;
}

.ChangePassInput::placeholder {
  color: #2C3E5D;
  font-size: 14px;
  font-weight: 400;
}

button#ChangePass {
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 500;
  background-color: rgb(44, 62, 93);
  border: unset;
  margin-top: 1rem;
  padding: 5px 15px;
  border-radius: 4px;
  width: auto;
}

#DisconnectModal span {
  font-size: 32px !important;
  text-align: center;
  font-weight: 600;
  color: #2C3E5D;
}

h6#DisconnectText {
  color: #1E2B40 !important;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
}

button#noButtons {
  margin-left: 1rem !important;
  margin-top: 2rem;
  background: transparent;
  border: 2px solid #9396A8;
  font-size: 12px;
  font-weight: 500;
  color: #888888;
}

div#DeleteModalContent {
  padding: 1rem 2rem 3rem 2rem;
}

h6#DeleteText {
  color: #1E2B40 !important;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
}

div#FAQ .group {
  border-radius: 8px !important;
  margin-top: 5px !important;
  /* 45px 5px 45px 5px */
  background-color: #2D3953 !important;
  /* #fff !important; */
}

/* div#LoginContent {
  border-radius: 45px 5px 45px 5px;
} */

.leaPGc {
  border-bottom-right-radius: 45px !important;
}

#ContactBtn {
  border: unset;
  background: #5a13e6;
}

#ContactBtn:hover {
  border: unset;
  background: #fff;
  color: #2C3E5D;
}

#ConnectBtn {
  display: flex;
  align-items: center;
  color: #2C3E5D;
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 400;
}

.CarouselSlider {
  max-width: 1000px;
  height: auto;
  border: 1px solid #dcdcdc;
  background: #fff;
  padding: 15px;
  box-shadow: 2px 5px 10px #dcdcdc;
  border-radius: 8px;
}

div#MyCardWrapper {
  width: 100% !important;
  /* margin-left: 5px !important;
  margin-right: 5px !important; */
  padding-left: 10px;
  margin-top: 0px !important;
  padding-bottom: 0.75rem !important;
}

div#MyCardWrapperAlldomain {
  width: 25% !important;
  /* margin-left: 5px !important;
  margin-right: 5px !important; */
  padding-left: 10px;
  margin-top: 0px !important;
  padding-bottom: 0.75rem !important;
  float: left;
}

div#MySearchCardWrapper {
  width: 25% !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

h2#vdowrapper {
  text-align: center;
  font-weight: 900;
  font-size: 3rem;
  margin-bottom: 20px;
}

#QuoteText {
  margin-bottom: 15px;
}



h1#card1 {
  font-size: 2.5rem;
  background: #2C3E5D;
  width: 100px;
  text-align: center;
  height: 100px;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  font-weight: 700;
  color: #fff;

}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: black;
  opacity: 0.6;
  z-index: 10;
  color: white;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 10px;
  border-color: #06584a;
  border-top-color: #dddddd;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
  opacity: 1;
}

.socialhead {
  font-size: 22px;
  color: #2C3E5D;
  font-weight: bold;
  text-align: center;
}

.socialMainWrapper {
  padding: 0px 0px 15px 0px;
}

button.fbsocial {
  padding-left: 10px;
  font-size: 18px;
}

button.twsocial {
  padding-left: 10px;
  font-size: 18px;
}

.CertifiedLogo {
  padding: 15px 0px 0px 25px;
  text-align: left;
}

.CertifiedLogo p {
  color: #3e3e3e;
  font-size: 14px;
}

.CertifiedLogo .flex {
  display: flex;
  align-items: center;
  padding-top: 4px;
  justify-content: center;
}

img.GoogleLogo {
  width: 72px;
  padding-right: 5px;
  padding-top: 2px;
}

img.MicrosoftLogo {
  width: 95px;
  padding-left: 5px;
}

div#progressBar {
  /* margin-top: 10px; */
  border-radius: 1.25rem;
  width: 100%;
  border: 1px solid #80808054;
  color: #000;
  text-align: center;
  line-height: unset;
  height: fit-content;
  /* margin-right: 5px; */
}

div#progressBar .progress-bar {
  min-height: 8px;
  /* background-color: #bee3f8; */
  background-color: #5071A8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  color: #000;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
}

.RAFPopup {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 9999;
  background: #2C3E5D;
  width: 60px;
  height: 60px;
  padding: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  vertical-align: middle;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
}

.RAFPopup .fa {
  font-size: 20px;
}

div#modalPopup {
  background-color: rgb(0 0 0 / 0%);
}

#PFMSidebar a:nth-child(5) {
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #dcdcdc;
}

body.ModalBackdrop {
  overflow: hidden;
}

/* div#DomainSearch {

  justify-content: end;
  display: flex;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 0px;
} */
div#DomainSearch {
  /* width: 80%; */
  /* justify-content: left;
  display: flex; */
  /* border: 1px solid #6417ff; */
  padding-bottom: 0px;
}

div.DomainSearchDSR {
  margin-top: 25px !important;
}

/* #DomainSearch div:nth-child(1) {
  background: transparent !important;
  box-shadow: unset !important;
} */

div#noSearchResult {
  color: red;
  padding-top: 5px;
}

div#tablemsg {
  color: green;
  padding-top: 5px;
}


.mt-20 {
  margin-top: 20px !important;
}


div#DomainSearch div:nth-child(1) {
  background-color: transparent;
  box-shadow: unset;
  /* width: 80%; */
  background-color: #fff;

}

.CarouselSlider .react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}

.CarouselSlider .react-multiple-carousel__arrow {
  background: rgba(91, 76, 121, 0.5) !important;
  min-width: 30px !important;
  min-height: 30px !important;
}

.CarouselSlider .react-multiple-carousel__arrow:hover {
  background: rgb(100, 21, 255, 0.8) !important;
}

.CarouselSlider button:focus {
  outline: unset !important;
}

.DomainDetailsModal {
  background: white !important;
  border: none !important;
  border-radius: 16px !important;
}


.DeletPopupHeader {
  padding: 3rem 2rem 1rem 2rem !important;
}

.DeletPopupHeader span {
  font-size: 32px !important;
  text-align: center;
  font-weight: 600;
  color: #2C3E5D;
}

.PopupClearIcon {
  width: 20px !important;
  height: auto !important;
}

.Tab_flex {
  display: flex;
  padding-bottom: 15px;
  /* align-items: center; */
  justify-content: space-between;
}

.TabTotalWrapper {
  /* position: absolute; */
  /* right: 40px; */
  text-align: right;
}

.pt-0 {
  padding-top: 0px !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

hr.ModalBorderLine {
  margin-left: -20px;
  margin-right: -20px;
  padding-top: 10px;
}

.DeletePopupBtn {
  margin-top: 1rem !important;
}

.MuiTabs-flexContainer button {
  min-width: 115px;
}

div#IntroBG {
  background-image: radial-gradient(70.53% 55.37% at 50% 44.63%, #EEF3FF 0%, #BFD9FF 100%) !important;
  height: auto !important;
  /* min-height: unset !important;
  max-height: unset !important; */
  min-height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.IntroHeading {
  font-size: 40px !important;
  font-weight: 600 !important;
}

.IntroImg {
  margin: 0 auto !important;
}


@media only screen and (max-width: 767px) {
  .makeStyles-email-12 {
    word-break: break-all;
  }

  .makeStyles-email-34 {
    word-break: break-all;
    width: 50%;
  }

  .eWtoBU {
    word-break: break-word;
  }

  div#CardContent:after {
    top: 0;
  }

  div#DSRCard {
    margin-bottom: 15px;
  }

  .makeStyles-content-2 {
    margin-left: 0px;
  }

  #DashboardCard {
    margin-bottom: 15px;
    padding-top: 0px;
  }

  .MuiButton-outlined {
    padding: 5px 10px !important;
  }

  .MuiButton-outlinedPrimary {
    min-width: 112px !important;
  }

  p#DataEmail {
    display: inline-block;
    width: 100%;
    white-space: break-spaces;
    overflow: hidden !important;
    text-overflow: ellipsis;
    word-break: break-all !important;
  }

  div#LoginContent {
    border-radius: 0px;
  }

  div#cardTextWrapper {
    padding-bottom: 5px;
  }

  .CarouselSlider {
    max-width: 300px;
  }

  div#MyCardWrapper {
    /* width: unset !important; */
  }

  iframe {
    width: -webkit-fill-available !important;
    height: auto;
  }

  div#AccountEmail .MuiSelect-selectMenu {
    /* white-space: unset !important;
  word-break: break-all; */
    width: 150px;
  }

  .View_d-flex {
    display: block;
    align-items: center;
  }

  .ViewRequestDeletionData {
    width: 100%;
  }

  .ViewRequestDeletionLogo {
    /* width: unset; */
    height: auto;
    display: block;
    margin-right: 10px;
  }

  .ViewTableCell {
    display: block !important;
  }

  th.ViewTableCell {
    width: 100%;
    padding-bottom: 0px !important;
  }

  .DomainStatus {
    padding-left: 0px;
  }

  .imgid {
    margin: 0;
  }


  .Tab_flex {
    /* display: block; */
  }

  #AllDomainTab div:nth-child(1) {
    display: block;
    /* word-wrap: normal; */
    white-space: break-spaces;
  }

  #RescanBtn.MuiButton-outlinedPrimary {
    margin-left: 0;
    margin-top: 5px;
  }

  #EmailConBox .MuiButton-outlinedSecondary {
    margin-top: 5px;
  }

  #DomainBtnWrapper .MuiGrid-root.MuiGrid-item {
    text-align: right;
  }

  #DomainBtnWrapper button {
    margin-bottom: 10px;
    margin-right: 5px;
  }

  /* Grid Css Start */
  #DSRCustomTable .MuiDataGrid-columnHeader {
    min-width: 130px !important;
    max-width: 130px !important;
  }

  #DSRCustomTable .MuiDataGrid-columnHeader:nth-child(1) {
    min-width: 50px !important;
  }

  #DSRCustomTable .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 200px !important;
    max-width: 200px !important;
  }

  #DSRCustomTable .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 160px !important;
  }

  #DSRCustomTable .MuiDataGrid-cell {
    min-width: 130px !important;
    max-width: 130px !important;
  }

  #DSRCustomTable .MuiDataGrid-cell:nth-child(1) {
    min-width: 50px !important;
  }

  #DSRCustomTable .MuiDataGrid-cell:nth-child(2) {
    min-width: 200px !important;
    max-width: 200px !important;
    /* word-break: break-all !important; */
  }

  #DSRCustomTable .MuiDataGrid-cell:nth-child(3) {
    min-width: 160px !important;
  }

  /* Grid Css Next */

  #DSRCustomTable1 .MuiDataGrid-columnHeader {
    min-width: 130px !important;
    max-width: 130px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-columnHeader:nth-child(1) {
    min-width: 200px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 160px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 140px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-cell {
    min-width: 130px !important;
    max-width: 130px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-cell:nth-child(1) {
    min-width: 200px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-cell:nth-child(2) {
    min-width: 160px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-cell:nth-child(3) {
    min-width: 140px !important;
  }

  /* #DSRCustomTable1 .MuiDataGrid-virtualScroller {
    overflow-x: auto !important;
}
  #DSRCustomTable1 .MuiDataGrid-row div:nth-child(9) {
    display: none !important;
} */
  /* Grid Css End */

  #DashboardCard1 {
    margin-top: 10px;
    margin-bottom: 6px;
  }

  /* Submitted & Successful Tab Grid */
  .CustomFilter {
    display: block;
    align-items: center;
  }

  div#SearchWrapper {
    margin-right: unset;
    margin-bottom: 5px;
    width: 100%;
  }

  .DomainTableOuterInput {
    padding: 5px;
    width: 100%;
  }

  /* Mobile View New Figma Design  */

  .ActiveCard {
    border-bottom: unset;
    border-left: 6px solid #5071A8;
  }

  #cardValue {
    font-size: 24px;
  }

  #cardDesc {
    display: none;
  }

  button#Refresh {
    float: unset !important;
    margin-bottom: 10px;
  }

  #GridHeading {
    font-size: 18px;
  }

  #GridSubHeading {
    font-size: 14px;
  }

  .OnHover {
    left: unset;
    right: 105%;
  }

  .OnHover::after {
    left: unset;
    right: -8%;
    transform: rotate(270deg);
    margin-left: 9px;
  }

  .MobileView {
    display: none;
  }

  .MobileView1 {
    display: none;
  }

  .MobileMr {
    margin-right: 15px;
  }

  button#AccountTab {
    font-size: 13px;
    font-weight: 500;
    font-family: 'Inter';
    color: #9396A8;
    padding-left: unset;
    padding-bottom: unset;
    margin-right: 10px;
    background: #E6E9F3;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px;
  }

  #AllDomainTab .Mui-selected {
    color: #fff !important;
    border-bottom: unset;
    background: #5071A8;
  }

  .TabPanelHeading {
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  .AccountHeading {
    font-size: 20px !important;
  }

  .DeletingAcBody {
    font-size: 14px !important;
  }

  div#EmailConBox {
    height: auto;
  }

  .makeStyles-email-59 {
    width: 50%;
  }

  #Text_Right {
    text-align: left;
  }

  .DeletPopupHeader span {
    font-size: 20px !important;
  }

  h6#DeleteText {
    font-size: 14px;
  }

  button#noButtons {
    margin-left: 0 !important;
    margin-top: 1rem;
  }

  #Percentage {
    width: 17% !important;
  }

  .makeStyles-email-15 {
    width: 40%;
  }

  .DesktopView {
    display: block;
  }

  .ViewRequestDeletionLogo a.DomainDetailName {
    word-break: break-all;
  }

  .DomainDetailsModal {
    width: 90% !important;
  }

  .LogoFlex {
    display: flex;
    margin-top: 15px;
  }

  .MobileSize {
    width: 25% !important;
  }

  .socialhead {
    padding-bottom: 10px;
  }

  .CopyURLBtn {
    float: unset;
  }

  div#DomainDtlBtn {
    display: inline-block;
  }

  #DomainDtlBtn button {
    margin-top: 10px;
    margin-bottom: 0;
  }

  #DomainLogoAlpha {
    width: 100%;
    height: 100%;
  }

  div#RequestDeletetionPopup {
    max-height: 80vh;
    overflow-y: auto;
  }

  .TimeFrameOption select option {
    font-size: 12px;
  }

  #ConnectBtn {
    display: block;
  }

  .ConnectLogo {
    display: flex;
  }

  .ConnectEmail span {
    margin-left: 0;
  }
}

@media only screen and (min-width: 280px) and (max-width: 560px) {

  /* .MobileMr{
    margin-right: 2px;
  } */
  #AllDomainTab {
    width: 100%;
  }

  .MobileMr img {
    width: 1rem;
    margin-right: 5px;
  }

  .DarkNavLink img {
    width: 1.5rem;
    margin-right: 0;
  }

  main {
    width: 100%;
  }
}

@media only screen and (min-width: 640) and (max-width: 767) {
  #DashboardCard.dVsRck {
    /* width: 50%; */
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  div#CardContent:after {
    top: 3.9%;
  }

  div#DSRCard {
    margin-bottom: 10px;
  }

  .makeStyles-content-2 {
    margin-left: 0px;
  }

  #DashboardCard {
    margin-bottom: 10px;
    width: 33%;
  }

  div#CardContent {
    display: block;
    text-align: center;
  }

  #cardValue {
    color: #000;
    font-size: 48px;
    padding-right: unset;
    font-weight: 600;
    font-family: 'Inter';
  }

  .CustomFilter {
    display: block;
  }

  div#SearchWrapper {
    width: 100%;
  }

  .DomainTableOuterInput {
    width: 100%;
    margin-top: 2%;
    justify-content: flex-start;
  }

  .CarouselSlider {
    max-width: 650px;
  }

  div#MyCardWrapper {
    /* width: 33.33% !important; */
  }

  iframe {
    /* width: fit-content !important; */
    /* height: auto; */
  }

  .View_d-flex {
    display: flex;
    align-items: flex-start;
  }

  /* .ViewRequestDeletionData {
    width: 100%;
  }

  .ViewRequestDeletionLogo {
    width: 100%;
    height: auto;
    text-align: center;
  }

  .ViewRequestDeletionLogo img {
    margin: 0 auto;
  } */

  #AllDomainTab div:nth-child(1) {
    display: block;
    /* word-wrap: normal; */
    white-space: break-spaces;
  }

  /* Grid Css Start */
  #DSRCustomTable .MuiDataGrid-columnHeader {
    min-width: 130px !important;
    max-width: 130px !important;
  }

  #DSRCustomTable .MuiDataGrid-columnHeader:nth-child(1) {
    min-width: 50px !important;
  }

  #DSRCustomTable .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 220px !important;
  }

  #DSRCustomTable .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 150px !important;
  }

  #DSRCustomTable .MuiDataGrid-cell {
    min-width: 130px !important;
    max-width: 130px !important;
  }

  #DSRCustomTable .MuiDataGrid-cell:nth-child(1) {
    min-width: 50px !important;
  }

  #DSRCustomTable .MuiDataGrid-cell:nth-child(2) {
    min-width: 220px !important;
  }

  #DSRCustomTable .MuiDataGrid-cell:nth-child(3) {
    min-width: 150px !important;
  }

  /* Grid Css Next */

  #DSRCustomTable1 .MuiDataGrid-columnHeader {
    min-width: 130px !important;
    max-width: 130px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-columnHeader:nth-child(1) {
    min-width: 200px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 150px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 140px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-cell {
    min-width: 130px !important;
    max-width: 130px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-cell:nth-child(1) {
    min-width: 200px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-cell:nth-child(2) {
    min-width: 150px !important;
  }

  #DSRCustomTable1 .MuiDataGrid-cell:nth-child(3) {
    min-width: 140px !important;
  }

  /* Grid Css Next */

  #DSRCustomTable2 .MuiDataGrid-columnHeader {
    min-width: 130px !important;
    max-width: 130px !important;
  }

  #DSRCustomTable2 .MuiDataGrid-columnHeader:nth-child(1) {
    min-width: 200px !important;
  }

  #DSRCustomTable2 .MuiDataGrid-columnHeader:nth-child(2) {
    min-width: 150px !important;
  }

  #DSRCustomTable2 .MuiDataGrid-columnHeader:nth-child(3) {
    min-width: 140px !important;
  }

  #DSRCustomTable2 .MuiDataGrid-cell {
    min-width: 130px !important;
    max-width: 130px !important;
  }

  #DSRCustomTable2 .MuiDataGrid-cell:nth-child(1) {
    min-width: 200px !important;
  }

  #DSRCustomTable2 .MuiDataGrid-cell:nth-child(2) {
    min-width: 150px !important;
  }

  #DSRCustomTable2 .MuiDataGrid-cell:nth-child(3) {
    min-width: 140px !important;
  }

  /* Grid Css End */
  .MobileMr {
    margin-right: 20px;
  }

  td.ViewTableCell {
    display: block;
  }

  .MobileView1 {
    display: block;
  }

  .DomainStatus {
    padding-left: 0;
  }

  button#AccountTab {
    font-size: 13px;
    font-weight: 500;
    font-family: 'Inter';
    color: #9396A8;
    padding-left: unset;
    padding-bottom: unset;
    margin-right: 10px;
    background: #E6E9F3;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px;
  }

  #AllDomainTab .Mui-selected {
    color: #fff !important;
    border-bottom: unset;
    background: #5071A8;
  }

  .AccountHeading {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #2C3E5D !important;
    font-family: "inter" !important;
  }

  .TabPanelHeading {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #2C3E5D !important;
    font-family: 'Inter' !important;
  }

  .DeletPopupHeader span {
    font-size: 20px !important;
  }

  h6#DeleteText {
    font-size: 14px;
  }

  div#DomainDtlBtn {
    display: inline-block;
  }

  #DomainDtlBtn button {
    margin-top: 10px;
  }
}

@media (min-width: 1024px) {
  .MobileView {
    display: unset !important;
  }
}

div#MyBackdrop {
  z-index: 0 !important;
  bottom: 0 !important;
}

div#MyAccountBackdrop {
  /* z-index: 0 !important; */
  bottom: 0 !important;
}

div#hidecard {
  display: none;
}

/* #MyBackdrop .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
  padding-top: 20px;
} */
.SearchErr {
  color: red;
  position: absolute;
  padding: 10px 0 20px 0;
}

div#mydataloader {
  text-align: center;
  margin-top: 7%;
}

.DsrCardCheckbox {
  margin-right: 10px;
  margin-top: 10px;
}

div.MultiDSRBtn button {
  width: auto !important;
  margin-left: inherit;
  border-radius: 4px !important;
  margin-right: 5px !important;

}

.MultiDSRBtn {
  margin-top: 16px;
  justify-content: left !important;
}

div#cardCheckWrapper {
  text-align: right;
}

.MuiBox-root {
  padding: 0px !important;
}

div#AllDomainTabWrapper {
  margin-top: 25px;
}

.rgt-wrapper {
  max-height: 500px !important;
}

#email-fca3033cbe729ef7-EJ4bIrBiHyPcM30C-description em {
  font-size: 12px;
}

.rgt-cell:not(.rgt-row-edit):not(.rgt-row-not-selectable) {
  cursor: pointer;
}

.rgt-row-selected {
  background: #f3f6f9;
}

.rgt-row-hover {
  background: #f7f7f7;
}

.DomainDropdown {
  padding: 0px 10px;
  border-left: 1px solid #DDDDDD;
}

h1#custdomainlogo {
  font-size: 0.7rem;
  background: #2C3E5D;
  width: 30px;
  height: 30px !important;
  padding: 7px 12px;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  /* margin: 0 auto; */
  border-radius: 50%;
  position: relative;
  font-weight: 700;
  color: #fff;
}

div#DomainBtnWrapper {
  padding: 25px 0px;
}

.MuiIconButton-root.Mui-disabled path {
  opacity: 0.2;
}


.ag-root-wrapper {
  --ag-border-color: none;
  --ag-borders-row: none;
}


.ag-header-row {
  --ag-header-foreground-color: #888888;
}


.ag-checkbox-input-wrapper .ag-checked {
  color: #000;
}

.ag-ltr .ag-cell {
  display: flex;
  align-items: center;
}


.ag-body-viewport.ag-layout-normal {
  background-color: #f0f6ff;
}

.ag-header {
  --ag-header-background-color: #F0F6FF;
}

.ag-row-hover {
  --ag-border-radius: 8px;
}

.sc-dkzDqf .eeyusW .App {
  padding: 0px;
}

.ag-row {
  color: #1E2B40 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background-color: #ffffff !important;
}

.ag-theme-alpine,
.ag-theme-alpine-dark {
  --ag-selected-row-background-color: rgb(240 246 255 / 48%) !important;
  --ag-row-hover-color: rgba(255, 255, 255, 0.6) !important;
  --ag-checkbox-unchecked-color: #ABABAB !important;
}

.ag-header {
  --ag-header-background-color: #F0F6FF;
  font-weight: 600;
}

.ag-checkbox-input-wrapper .ag-checked {
  color: #000;
  background-color: #000 !important;
}

.ag-header-row {
  --ag-header-foreground-color: #888888;
  font-weight: 600 !important;
  font-size: 12px !important;
}

#DSRCustomTable .MuiDataGrid-row.Mui-selected {
  background-color: rgba(255, 255, 255, 0.6);
  background-color: hsla(0, 0%, 100%, .6);
}

.customerslider {
  width: 50% !important;
  word-wrap: break-word !important;
}

.orspeacing {
  margin: 0 10px;
  color: #757575 !important;
}

.orBorder {
  border-bottom: 1px solid #DDDDDD;
  width: 200px;
  display: inline-block;
}

.orflex {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-left: 0 !important;
  padding-right: 0 !important;


}

.orrow {
  border-bottom: 0 !important;
  margin-top: 1rem !important;
  margin-bottom: 5rem !important;

}

.orrowsignup {
  border-bottom: 0 !important;
  margin-top: 1rem !important;
  margin-bottom: 7rem !important;
}

.textColor {
  color: #757575 !important;


}

.signInColor {
  color: #334F7E !important;
  font-weight: 500;

}

.marginTop {
  margin-top: 35px !important;
  color: #757575 !important;
}

#iddataSubReq .MuiPaper-rounded {
  border-radius: 16px !important;
}

#linkAddress a {
  color: #1059d6;
}

.App1 {
  padding-top: 40px;
}