 :before, *:after {
    box-sizing: border-box;
}

.g-sign-in-button {
    margin: 10px;
    display: inline-block;
    width: max-content;

    height: 57px;
    background-color: #fff;
    color: rgb(0, 0, 0, .54);
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background-color .218s, border-color .218s, box-shadow .218s;
}

.g-sign-in-button:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}

.g-sign-in-button:active {
    background-color: #fff;
    transition: background-color 0.2s;
}

.g-sign-in-button .content-wrapper {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
    align-items: center;
    display: flex;
}

.g-sign-in-button img {
    width: 27px;
    height: 27px;
}

.g-sign-in-button .logo-wrapper {
    padding: 12px 36px 12px 12px;
text-align: center;
align-items: center;
background: #fff;

border-radius: 1px;
display: inline-block;
}
.g-sign-in-button .logo-wrapper1 {
    padding: 12px;
text-align: center;
align-items: center;
background: #fff;

border-radius: 1px;
display: inline-block;
}
.g-sign-in-button .text-container {
            font-family: Roboto,arial,sans-serif;
font-weight: 500;
/* text-transform: uppercase; */
letter-spacing: .21px;
font-size: 14px;
line-height: 5px;
vertical-align: text-top;
border: none;
display: inline-block;
text-align: center;
padding-right: 12px;
}

.SocialLoginBtn{
    background-color: #F8F8F8 !important
  }
  .SocialLoginBtn:hover{
    background-color: rgba(247,250,252) !important
  }