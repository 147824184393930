.customDot {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
}

.dot {

    width: 10px;
    height: 10px;
    background: #5071A8;
    border-radius: 50%;
    margin-right: 8px;
}

.dot.active {
    width: 10px;
    height: 10px;
    padding: 4px;
    background: #70C7F8;
    border-radius: 50%;
    border: 4px solid #2C3E5D;
}

